/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/


/*
########    #######    ######         ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ##    ##        ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  ##              ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ##              ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##              ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##    ##        ##    ##   ##         ##     ##  ##     ##     ##      
########    #######    ######         ##     ##  ########   ##     ##  ########      ##      
*/
$(document).ready(function() {

	FastClick.attach(document.body);

	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	/**
	 * Toggle menu
	 */
	$(".nav-toggle").click(function(e){
		e.preventDefault();
		$(".header__menu").toggleClass("open");
		$(".lines-button").toggleClass("close");
	});


	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/
	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	$(".contact-input").focusin(function() {
		$(this).parent('li').addClass('input-active');
	}).blur(function() {
		if( $(this).val() === "" ) $(this).parent('li').removeClass('input-active');		
	});

	$("#fContact").submit(function(){
		if($("#fContact").validationEngine('validate')){
			var url  = $(this).attr('action');
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : url,
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});
});
